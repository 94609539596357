import * as moment from 'moment';
import { Link } from 'gatsby';
import React from 'react';
import { ResourceType } from 'types/resource-type';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';

interface FullWidthResourceCardProps {
  title: string;
  url: string;
  imageUrl: string;
  date: string;
  type: ResourceType;
  description: string;
  eventLocation?: string;
}

const FullWidthResourceCard: React.FC<FullWidthResourceCardProps> = ({
  title,
  url,
  imageUrl,
  type,
  date,
  description,
  eventLocation,
}) => {
  return (
    <Link
      to={url}
      className="u-container px-6 lg:px-md xl:px-0 mb-16 flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 transition hover:opacity-50 focus:opacity-50"
    >
      <div className="w-full lg:w-1/2 flex-shrink-0 lg:pr-12">
        <div className="relative aspect-w-16 aspect-h-10 md:aspect-h-12">
          <img
            src={imageUrl}
            alt={title}
            className="absolute inset-0 object-cover"
          />
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex-shrink-0 flex-grow-0 lg:pl-12">
        <div className="flex items-center mb-3 space-x-11">
          <ResourceTypeTag type={type} inline />
          {type !== 'event' && (
            <div className="font-bold text-mid-grey text-sm tracking-tighter leading-8 uppercase">
              {moment(date).format('MMM DD, YYYY')}
            </div>
          )}
        </div>
        <h2 className="u-h2 mb-3.5">{title}</h2>
        {type === 'event' ? (
          <div className="flex items-center space-x-8 lg:space-x-14">
            <div className="flex-grow-0">
              <div className="text-sk-blue text-4xl lg:text-6xl leading-none font-script">
                {moment(date).format('Do')}
              </div>
              <div className="text-sm lg:text-base leading-tight">
                {moment(date).format('MMMM')}
              </div>
            </div>
            <div className="flex-grow-0">
              <div className="text-sk-blue text-4xl lg:text-6xl leading-none font-script">
                {moment(date).format('HH:mm')}
              </div>
              <div className="text-sm lg:text-base leading-tight">
                Start time
              </div>
            </div>
            <div className="flex-grow-0">
              <div className="text-sk-blue font-semibold text-2xl lg:text-4.5xl tracking-tightest leading-normal">
                {eventLocation}
              </div>
              <div className="text-sm lg:text-base leading-tight">Location</div>
            </div>
          </div>
        ) : (
          <>
            <p>{description}</p>
            <div className="flex justify-start">
              <div className="u-cta-link w-auto mt-6">Read more</div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default FullWidthResourceCard;
