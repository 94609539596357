import React from 'react';
import useNewsletter from 'hooks/use-newsletter';
import * as ChevronRightIcon from 'images/chevron-right.inline.svg';

interface NewsletterCtaSectionProps {
  headline: string;
  background?: string;
}

const NewsletterCtaSection: React.FC<NewsletterCtaSectionProps> = ({
  headline,
  background,
}) => {
  const {
    values: { email },
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    error,
    response
  } = useNewsletter();

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500 mt-4">{errors[name]}</div>
    ) : null;
  };

  return (
    <div className={background || 'bg-ghost-grey text-dark-grey '}>
      <div className="container px-6 py-20 md:py-40 max-w-4xl text-center mx-auto">
        {!response?.ok && <h2 className="u-h2 mb-8">{headline}</h2>}
        {response?.ok ? (
          <div className="text-center u-h2">Thank you for subscribing</div>
        ) : (
          <form action="/" onSubmit={handleSubmit}>
            <div className="max-w-full relative inline-block">
              <input
                className="w-input max-w-full h-14 pl-7 pr-19 md:pr-48 md:text-lg bg-white rounded-full border border-border-grey placeholder:text-mid-grey focus:outline-none focus:ring-1 focus:ring-offset-primary text-dark-grey"
                type="email"
                name="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`flex justify-center pl-px items-center text-center bg-primary text-white md:px-10 py-0 md:text-lg font-semibold tracking-tighter absolute top-1 right-1 rounded-full md:pt-px transition hover:opacity-75 w-16 md:w-auto h-12 focus:outline-none ${
                  isSubmitting ? 'opacity-50' : ''
                }`}
              >
                <span className="hidden md:inline-block mx-auto">Submit</span>
                <ChevronRightIcon className="w-auto h-4 inline md:hidden" />
              </button>
            </div>
            <ErrorMessage name="email" />
            {error && (
              <div className="text-red-500 mt-4">{response.data?.message}</div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default NewsletterCtaSection;
