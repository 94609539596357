import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import CtaSection from 'components/organisms/CtaSection';
import FullWidthResourceCard from 'components/organisms/FullWidthResourceCard';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const BlogPageContent = ({ newsletterCta, cta, resources = [] }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className="u-container pt-32 md:pt-48 mb-12 md:mb-24 px-6 lg:px-md xl:px-0">
        <Link
          className="inline-flex items-center text-violet-alt font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
          to="/resources"
        >
          <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
          Resources
        </Link>
        <h1 className="u-h1 mt-6 md:mt-8">The Skupos Blog</h1>
      </div>

      {resources.slice(0, 4).map((resource, index) => (
        <FullWidthResourceCard key={index} {...resource} />
      ))}

      <NewsletterCtaSection
        headline={newsletterCta.headline}
        background="bg-violet-alt text-white"
      />

      {resources.length > 4 && (
        <div className="pt-20 md:pt-32">
          {resources
            .slice(4, showAll ? undefined : 8)
            .map((resource, index) => (
              <FullWidthResourceCard key={index} {...resource} />
            ))}
        </div>
      )}

      {resources.length > 8 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <CtaSection headline={cta.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'C-Stores: What Happened in 2020 and What’s Next for 2021',
    date: '2021-01-16T00:52:53.179Z',
    imageUrl: '/uploads/graphic_blog_2020-wrap-up_1600x1200px.png',
    url: '/blog/press',
    type: 'blog-post',
    description:
      'Over the course of 2020, many factors impacted consumer buying behavior. We’ve outlined key trends, and how we believe brands and retailers can use this information to guide 2021 decisions.',
  },
];

export const BlogPagePreview = ({ entry }) => {
  return (
    <BlogPageContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const BlogPage = ({
  data: {
    markdownRemark: { frontmatter },
    posts,
  },
}) => {
  const { seo } = frontmatter;

  const resources = posts.edges.map(({ node }) => {
    const { title, date, featuredImage, slug, description } =
      node.frontmatter || {};

    return {
      title,
      date,
      imageUrl: featuredImage,
      url: `/blog/${slug}`,
      type: 'blog-post',
      description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <BlogPageContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/blogs/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            description
          }
        }
      }
    }
  }
`;

export default BlogPage;
