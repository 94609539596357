import useFetch from 'use-http';
import * as Yup from 'yup';
import useHubspot from 'hooks/use-hubspot';

export interface NewsletterValues {
  email: string;
}

const useNewsletter = () => {
  const { post, error, response } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/f67be5f6-838e-4a1c-97be-bc00b9e2fc1a',
  );

  const initialValues: NewsletterValues = {
    email: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide valid email')
      .required('Email address is required'),
  });

  const gtmVariables = {
    event: 'Subscribing Newsletter Succeeded',
    'gtm.elementClasses': 'subscribing-newsletter',
    'gtm.elementId': 'subscribing-newsletter-form',
  }

  const formik = useHubspot({ initialValues, schema, post, gtmVariables });

  return {
    ...formik,
    error,
    response
  }
};

export default useNewsletter;
