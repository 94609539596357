import { Link } from 'gatsby';
import React from 'react';

interface CtaButtonProps {
  to: string;
  label: string;
  inverted?: boolean;
  className?: string;
}

const CtaButton: React.FC<CtaButtonProps> = ({
  to,
  label,
  inverted,
  className,
}) => {
  const colors = inverted ? 'u-btn--green' : 'u-btn--white';
  const externalPrefixes = ['mailto:', 'tel:', 'http:', 'https:'];

  return externalPrefixes.some((prefix) => !!~to.indexOf(prefix)) ? (
    <a href={to} className={`u-btn ${colors} ${className}`}>
      {label}
    </a>
  ) : (
    <Link to={to} className={`u-btn ${colors} ${className}`}>
      {label}
    </Link>
  );
};

export default CtaButton;
