import React from 'react';
import CtaButton from 'components/atoms/CtaButton';

interface CtaSectionProps {
  headline: string;
}

const CtaSection: React.FC<CtaSectionProps> = ({ headline }) => {
  return (
    <div className="bg-primary-light">
      <div className="container max-w-screen-xl px-10 pt-24 pb-20 mx-auto text-center md:px-20 lg:px-40 xl:px-64 md:pt-48 md:pb-40">
        <h2 className="u-h2 text-white  mb-9">
          {headline}
        </h2>

        <CtaButton to="/get-started" label="Get started" />
      </div>
    </div>
  );
};

export default CtaSection;
