import React from 'react';
import { ResourceType } from 'types/resource-type';

interface ResourceTypeTagProps {
  type: ResourceType;
  overrideColor?: string;
  noIcon?: boolean;
  inline?: boolean;
  customerStoriesFullLabel?: boolean;
}

const ResourceTypeTag: React.FC<ResourceTypeTagProps> = ({
  type,
  overrideColor,
  noIcon,
  inline,
  customerStoriesFullLabel,
}) => {
  const iconMap = {
    'press-release': (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="13"
        fill="none"
        viewBox="0 0 15 13"
      >
        <path
          fill="#4EC0C4"
          d="M4.344 6.118H.918v4.197c0 .856.6 1.627 1.456 1.798 1.028.172 1.97-.685 1.97-1.713V6.118zM13.765.123H6.913c-.514 0-.856.342-.856.856V10.4c0 .6-.171 1.2-.514 1.713h6.51c1.455 0 2.569-1.113 2.569-2.57V.98c0-.514-.343-.856-.857-.856z"
        ></path>
      </svg>
    ),
    'blog-post': (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="currentColor"
          d="M6.177 2.231L1.333.155C1.073-.018.728.07.468.242.295.415.122.674.122.934v9.515c0 .346.173.692.52.779l5.535 2.422V2.23zM13.617.242c-.26-.173-.52-.173-.78-.087l-4.93 2.077V13.65l5.537-2.336c.345-.173.518-.432.518-.778V.934c0-.26-.172-.519-.345-.692z"
        ></path>
      </svg>
    ),
    'customer-story-brand': (
      <svg
        className="h-3.5 w-auto"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
        viewBox="0 0 14 15"
      >
        <path
          fill="currentColor"
          d="M12.218.703h-10.5a.875.875 0 00-.875.875v5.25a.875.875 0 00.875.875h10.5a.875.875 0 00.875-.875v-5.25a.875.875 0 00-.875-.875zM13.093 9.453H.843v1.75h12.25v-1.75zM13.093 12.953H.843v1.75h12.25v-1.75z"
        ></path>
      </svg>
    ),
    'customer-story-retail': (
      <svg
        className="h-3.5 w-auto"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
        viewBox="0 0 14 15"
      >
        <path
          fill="currentColor"
          d="M12.218.703h-10.5a.875.875 0 00-.875.875v5.25a.875.875 0 00.875.875h10.5a.875.875 0 00.875-.875v-5.25a.875.875 0 00-.875-.875zM13.093 9.453H.843v1.75h12.25v-1.75zM13.093 12.953H.843v1.75h12.25v-1.75z"
        ></path>
      </svg>
    ),
    report: (
      <svg
        className="w-auto h-4"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        fill="none"
        viewBox="0 0 17 17"
      >
        <path
          fill="currentColor"
          d="M7.122 2.692c-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7h-7v-7z"
        ></path>
        <path fill="currentColor" d="M9.122.692v7h7c0-3.9-3.1-7-7-7z"></path>
      </svg>
    ),
    'case-study': (
      <svg
        className="h-3.5 w-auto"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
        viewBox="0 0 14 15"
      >
        <path
          fill="currentColor"
          d="M12.218.703h-10.5a.875.875 0 00-.875.875v5.25a.875.875 0 00.875.875h10.5a.875.875 0 00.875-.875v-5.25a.875.875 0 00-.875-.875zM13.093 9.453H.843v1.75h12.25v-1.75zM13.093 12.953H.843v1.75h12.25v-1.75z"
        ></path>
      </svg>
    ),
    video: (
      <svg
        className="w-4 h-auto"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="13"
        fill="none"
        viewBox="0 0 17 13"
      >
        <path
          fill="currentColor"
          d="M11.926 4.848v-3c0-.6-.4-1-1-1h-9c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h9c.6 0 1-.4 1-1v-3l5 2v-8l-5 2z"
        ></path>
      </svg>
    ),
    'product-update': (
      <svg
        className="w-auto h-3.5"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        fill="none"
        viewBox="0 0 14 15"
      >
        <path
          fill="#F8962F"
          d="M6.055 3.116L1.211 1.04C.951.867.606.953.346 1.126.173 1.3 0 1.56 0 1.818v9.516c0 .346.173.692.519.778l5.536 2.422V3.116zM13.494 1.126c-.26-.173-.519-.173-.778-.086l-4.93 2.076v11.418L13.32 12.2c.346-.173.52-.433.52-.779V1.818c0-.26-.174-.519-.347-.692z"
        ></path>
      </svg>
    ),
    event: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        fill="none"
        viewBox="0 0 18 19"
      >
        <g fill="#4CC3D2" clipPath="url(#clip0)">
          <path d="M10.961 1.883a.418.418 0 00-.412.077L6.58 5.406l-2.866.768a2.963 2.963 0 00-1.843 1.49 3.404 3.404 0 00-.266 2.472c.226.841.753 1.564 1.467 2.01a2.963 2.963 0 002.34.367l2.867-.768 5.16 1a.411.411 0 00.396-.139.457.457 0 00.099-.202.495.495 0 00-.007-.231l-2.669-9.961a.495.495 0 00-.11-.203.458.458 0 00-.186-.126zM12.951 5.155l.97 3.622c.45-.12.828-.426 1.054-.85.226-.425.28-.934.152-1.414a1.945 1.945 0 00-.838-1.148 1.693 1.693 0 00-1.338-.21zM8.296 12.71l-2.44.655 2.44 3.388c.192.265.472.444.78.495.308.051.618-.028.862-.221.245-.193.404-.484.443-.81a1.332 1.332 0 00-.236-.923l-1.85-2.583z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path
              fill="#fff"
              d="M0 0H14V15H0z"
              transform="rotate(-15 16.156 2.021)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    ),
    download: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="currentColor"
          d="M6.055 2.457L1.211.381C.951.208.606.294.346.467.173.64 0 .9 0 1.16v9.516c0 .346.173.692.519.778l5.536 2.423V2.457zM13.494.468c-.26-.173-.519-.173-.778-.087l-4.93 2.076v11.419l5.535-2.336c.346-.173.52-.432.52-.778V1.16c0-.26-.174-.52-.347-.692z"
        ></path>
      </svg>
    ),
  };

  const colorMap = {
    'press-release': 'text-aqua',
    'blog-post': 'text-violet-alt',
    'customer-story-brand': 'text-berry',
    'customer-story-retail': 'text-orange',
    report: 'text-aqua',
    'case-study': 'text-aqua',
    video: 'text-berry',
    'product-update': 'text-orange',
    event: 'text-sk-blue',
    download: 'text-yellow',
  };

  const labelMap = {
    'press-release': 'Press Release',
    'blog-post': 'Article',
    'customer-story-brand': 'Brand',
    'customer-story-retail': 'Retail',
    report: 'Report',
    'case-study': 'Case Study',
    'product-update': 'Product Update',
    video: 'Video',
    event: 'Event',
    download: 'Download',
  };

  return (
    <div
      className={`${
        inline ? '' : 'mb-2'
      } flex items-center uppercase text-sm tracking-tighter leading-none font-bold ${
        overrideColor || colorMap[type]
      }`}
    >
      {!noIcon && <div className="mr-2">{iconMap[type]}</div>}
      <div className="mt-0.5">
        {(type === 'customer-story-brand' ||
          type === 'customer-story-retail') &&
        customerStoriesFullLabel
          ? `${labelMap[type]} Customer Story`
          : labelMap[type]}
      </div>
    </div>
  );
};

export default ResourceTypeTag;
